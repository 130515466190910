;(function($)
{
    'use strict';
    $(document).ready(function()
    {
        alertDelete();
    });

})(window.jQuery);

function alertDelete () {
    $('table.table').on('click', '.modal-delete', function(e){
        e.preventDefault();
        var linkUrl = $(this).attr('href');
        var titleAlert = $(this).attr('data-title');
        if (!titleAlert) {
            titleAlert = 'Excluir registro';
        };
        var descAlert = $(this).attr('data-desc');
        if (!descAlert) {
            descAlert = 'Deseja realmente excluir esse registro definitivamente?';
        };
        swal({
            title: titleAlert,
            text: descAlert,
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Sim, desejo excluir!",
            closeOnConfirm: false,
            cancelButtonText: "Cancelar",
        }, function() {
            window.location=linkUrl;
        });
    });

}

function associatedForm () {
    $('#cpf').mask('999.999.999-99');
    $('#zip_code').mask('99999-999');
    $('#fone').mask('(99) 9999-9999');
    $('#cellphone').mask('(99) 9999?9-9999');
    $('#date_birth').mask('99/99/9999');

    $("#cellphone").on("blur", function() {
        var last = $(this).val().substr( $(this).val().indexOf("-") + 1 );

        if( last.length == 3 ) {
            var move = $(this).val().substr( $(this).val().indexOf("-") - 1, 1 );
            var lastfour = move + last;
            var first = $(this).val().substr( 0, 9 );

            $(this).val( first + '-' + lastfour );
        }
    });
}